<template>
  <v-list>
    <div class="pa-4 px-sm-0 d-flex align-center">
      <div class="flex-shrink-0 flex-grow-1 text-h4">
        Ваши счета
      </div>
    </div>
    <v-divider></v-divider>
    <v-progress-linear
        v-if="listLoading"
        indeterminate
        color="primary darken-2"
    />
    <div
        v-for="(facilityCode) in Object.keys(invoicesList)"
        :key="facilityCode"
    >
      <v-subheader class="pt-5 px-4 px-sm-0">
        <div class="flex-grow-1 flex-shrink-0 text-h6">{{ getFacilityTitle(facilityCode) }}</div>
      </v-subheader>
      <div
          v-for="(item) in sortInvoices(invoicesList[facilityCode])"
          :key="item.invoiceId"
      >
        <v-list-item>
          <v-list-item-avatar v-if="$vuetify.breakpoint.mdAndUp"></v-list-item-avatar>
          <v-list-item-content class="box-list">
            <v-list-item-title style="flex: 2 0 10%">
              <div>
                <span class="d-none d-md-inline">Счет</span>
                <a href="#" @click.stop.prevent="download(item.order_id)" class="pdf-link" target="_blank" rel="nofollow">
                  <v-icon color="primary">mdi-pdf-box</v-icon>
                  {{ item.invoiceNumber }}
                </a>
              </div>
              <div>{{ date.isoToHuman(item.invoiceDate) }}</div>
            </v-list-item-title>
            <v-list-item-subtitle>
              <div>{{ item.objectIds }}</div>
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              <div>{{ format.toCurrency(item.total) }}</div>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action class="col-sm-3 col-md-2 col-5 ml-0 expanded">
            <div v-if="!item.outstanding">
              <v-icon left color="success">mdi-check</v-icon>
              <small>Оплачено</small>
            </div>
            <div
                v-else
            >
              <a
                  v-if="user.vat"
                  class="pdf-link"
                  rel="nofollow"
                  href="#"
                  @click.stop.prevent="download(item.order_id)"
              >
                <v-icon color="primary">mdi-pdf-box</v-icon>
                Скачать счет
              </a>
              <v-btn
                  v-else
                  color="primary"
                  outlined
                  large
                  @click="openDialog(item)"
                  block
              >
                <v-icon left>mdi-credit-card-outline</v-icon>
                {{ format.toCurrency(item.outstanding) }}
              </v-btn>
            </div>
          </v-list-item-action>
        </v-list-item>
        <v-divider :inset="$vuetify.breakpoint.mdAndUp"></v-divider>
      </div>
    </div>
    <pr-dialog max-width="330" v-model="invoiceDialog" no-full>
      <v-card>
        <v-card-title class="headline">
          <div v-if="!contractsCount">Оплата заблокирована</div>
          <div v-else>К оплате {{ format.toCurrency(invoice.outstanding) }}</div>
        </v-card-title>
        <v-card-text v-if="!contractsCount" class="pay-buttons">
          У вас нет действующих договоров аренды. Для погашения задолженности и получения доступа к вашим вещам, пожалуйста, свяжитесь с администрацией:
          <a href="tel:+78122070007" class="d-block">+7 (812) 207-00-07</a>
          <a href="https://t.me/Prostor_Self_Storage_Bot" class="d-block">Телеграм</a>
          <a href="https://wa.me/78122070007" class="d-block">WhatsApp</a>
        </v-card-text>
        <v-card-text v-else class="pay-buttons">
          <p class="p-1 red white--text" v-if="searchParams.error === 'payment_expired'">Время сессии истекло. Пожалуйста, попробуйте оплатить снова.</p>
          <p class="p-1" v-if="invoiceHasSubscription(invoice)">
            Подключён автоплатёж, оплата спишется автоматически. Чтобы оплатить сейчас, отключите его.
          </p>
          <v-checkbox v-if="!invoiceHasSubscription(invoice) && !invoice.paid && !invoice.promo && invoice.invoiceType !== 9" v-model="autopayment" label="Подключить автоплатеж"></v-checkbox>
          <v-divider></v-divider>
          <v-btn
              color="primary"
              large
              block
              @click="invoiceDialog = false"
              v-if="invoiceHasSubscription(invoice)"
          >
            Понятно
          </v-btn>
          <div
              v-else
          >
            <v-btn
                color="primary"
                outlined
                large
                block
                @click="payInvoice(`${invoice.siteCode}/${invoice.invoiceId}`)"
                :loading="loading"
                :disabled="loading">
              <v-icon left>mdi-credit-card-outline</v-icon>
              Оплатить
            </v-btn>
            <v-btn
                v-if='invoice.outstanding'
                @click="payInvoice(`${invoice.siteCode}/${invoice.invoiceId}`, {paymentMethod: 'sbp'})"
                :disabled="loading"
                :loading="loading"
                color="primary"
                outlined
                class="mt-4"
                large
                block
              >
              <v-img style="max-width: 16px" :src="`${PROFILE_DOMAIN}/img/sbp.svg`"/>
              {{ "Оплатить через СБП" }}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </pr-dialog>
    <alert-banner v-model="error"/>
  </v-list>
</template>
<style scoped>
.expanded > *:nth-child(n+2) {
  margin-top: 16px !important;
}
</style>
<script>
import date from "@/lib/date";
import format from "@/lib/format";
import CONFIG from "@/config";
import PrDialog from "@/components/PrDialog";
import FacilityRepository from "@/repository/facility";
import InvoiceRepository from "@/repository/invoice";
import SubscriptionRepository from "@/repository/subscription";
import UnitRepository from "@/repository/unit";
import CustomerRepository from "@/repository/customer";
import AlertBanner from "@/components/AlertBanner";

export default {
  name: "ListInvoices",
  components: {
    PrDialog,
    AlertBanner
  },
  data() {
    return {
      invoiceDialog: false,
      invoice: {},
      autopayment: false,
      loading: false,
      listLoading: false,
      invoices: [],
      subscriptions: [],
      units: [],
      searchParams: {},
      date,
      format,
      CONFIG,
      env: process.env,
      error: "",
      PROFILE_DOMAIN: process.env.VUE_APP_PROFILE_DOMAIN,
    };
  },
  props: {
    entityId: {
      type: String,
      default: null,
    },
    user: {
      type: Object,
      default: () => ({}),
    }
  },
  async mounted() {
    await this.loadData();
    if (this.entityId) {
      document.location.search
          .substr(1)
          .split("&")
          .forEach((value) => {
            const val = value.split("=");
            this.searchParams[val[0]] = val[1];
          });
      let [siteCode, invoiceId] = this.entityId.split('-');
      let invoice = this.invoices.find(invoice => invoice.siteCode == siteCode && invoice.invoiceId == invoiceId);
      if (invoice && invoice.outstanding) {
        this.openDialog(invoice);
      }
    }
  },
  computed: {
    invoicesList() {
      return this.invoices.reduce((list, curr) => {
            curr.subscription = this.subscriptions.find(subscription => curr.subscription_id == subscription.id);
            list[curr.siteCode] ||= [];
            list[curr.siteCode].push(curr);
            return list;
          },
          {},
      );
    },
    contractsCount() {
      return this.units.filter(unit => unit.contractStatus === "Active").length;
    }
  },
  methods: {
    invoiceHasSubscription(invoice) {
      return invoice.invoiceType !== 9 && invoice.subscription && invoice.subscription.next && `${invoice.subscription.next}T00:00:00` >= invoice.dueDate
    },
    async loadData() {
      this.listLoading = true;
      await FacilityRepository.list();
      this.invoices = await InvoiceRepository.list();
      this.subscriptions = await SubscriptionRepository.list();
      this.units = await UnitRepository.list();
      this.listLoading = false;
    },
    sortInvoices(invoices) {
      return invoices
          .sort((a, b) => {
            if (a.outstanding && !b.outstanding) return -1;
            if (!a.outstanding && b.outstanding) return 1;
            if (a.subscription?.next && !b.subscription?.next) return -1;
            if (!a.subscription?.next && b.subscription?.next) return 1;
            return b.invoiceDate.localeCompare(a.invoiceDate);
          });
    },
    openDialog(invoice) {
      this.invoice = invoice;
      this.invoiceDialog = true;
    },
    async appleValidator(url) {
      return await CustomerRepository.validateAppleSession({url});
    },
    async aPayInvoice(invoiceId, data) {
      return await this.payInvoice(invoiceId, {paymentMethod: "apay", paymentData: data});
    },
    async gPayInvoice(invoiceId, data) {
      return await this.payInvoice(invoiceId, {paymentMethod: "gpay", paymentData: data.paymentMethodData.tokenizationData.token});
    },
    async payInvoice(invoiceId, {paymentMethod = null, paymentData = null} = {}) {
      this.loading = true;
      try {
        const pay = await CustomerRepository.placeInvoice({
          invoice: invoiceId,
          data: {
            recurring: this.autopayment ? 1 : 0,
            paymentMethod,
            paymentData,
          }
        });

        if (pay.url) {
          if (pay.url_method === 'POST') {
            let form = document.createElement('form');
            form.action = pay.url;
            form.method = pay.url_method;
            Object.keys(pay.url_params).forEach(key => {
              let input = document.createElement('input');
              input.type = 'text';
              input.name = key;
              input.value = pay.url_params[key];
              form.appendChild(input);
            });
            document.body.appendChild(form);
            form.submit();
          } else {
            document.location = pay.url;
          }
        }
      } catch(e) {
        this.error = e.response.data.message;
      }
      this.invoiceDialog = false;
      this.invoice = {};
      this.loading = false;
    },
    getFacilityTitle(code) {
      const facility = FacilityRepository.get(code);
      return facility ? facility.name : "";
    },
    async download(id) {
      await CustomerRepository.download({url: `${CONFIG.api.profile.invoices}/invoice-${id}.pdf`, name: `invoice-${id}.pdf`})
    }
  },
};
</script>

<style scoped>

</style>
